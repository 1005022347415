import { Global } from './types';

function getGlobal(target: any): null | Global {
  return target && target.Math === Math ? target : null;
}

export default getGlobal(typeof globalThis === 'object' && globalThis) ||
  getGlobal(typeof window === 'object' && window) ||
  getGlobal(typeof global === 'object' && global) ||
  // eslint-disable-next-line no-restricted-globals
  getGlobal(typeof self === 'object' && self) ||
  getGlobal(
    // eslint-disable-next-line func-names
    (function () {
      // @ts-ignore
      return this;
    })(),
  ) ||
  // eslint-disable-next-line no-new-func
  getGlobal(Function('return this')()) ||
  {};
