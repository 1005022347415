import { Logger, LogLevel } from './types';

class ProxyLogger implements Logger {
  private _name: string;

  private _logger: Logger;

  constructor(name: string, logger: Logger) {
    this._name = name;
    this._logger = logger;
  }

  get name() {
    return this._name;
  }

  getLogger() {
    return this._logger;
  }

  setLogger(logger: Logger) {
    this._logger = logger;
  }

  log(level: LogLevel, message?: any, ...optionalParams: any[]) {
    const prefix = this._name ? `[${this._name}]` : '';

    if (typeof message === 'string') {
      return this._logger[level]([prefix, message].join(' '), ...optionalParams);
    }

    return this._logger[level](prefix, message, ...optionalParams);
  }

  silly(message?: any, ...optionalParams: any[]) {
    return this.log('silly', message, ...optionalParams);
  }

  debug(message?: any, ...optionalParams: any[]) {
    return this.log('debug', message, ...optionalParams);
  }

  verbose(message?: any, ...optionalParams: any[]) {
    return this.log('verbose', message, ...optionalParams);
  }

  info(message?: any, ...optionalParams: any[]) {
    return this.log('info', message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    return this.log('warn', message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]) {
    return this.log('error', message, ...optionalParams);
  }
}

export default ProxyLogger;
