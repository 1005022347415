import { Logger } from './types';
import ProxyLogger from './ProxyLogger';

class LoggerManager {
  private _rootLogger: Logger;

  private _store: Map<string, ProxyLogger>;

  constructor(rootLogger: Logger) {
    this._rootLogger = rootLogger;
    this._store = new Map();
  }

  get size() {
    return this._store.size;
  }

  each(callback: (name: string, proxyLogger: ProxyLogger) => void | boolean) {
    for (const [name, proxyLogger] of this._store.entries()) {
      const keep = callback(name, proxyLogger);

      if (keep === false) {
        break;
      }
    }
  }

  getRootLogger() {
    return this._rootLogger;
  }

  setRootLogger(rootLogger: Logger) {
    if (this._rootLogger !== rootLogger) {
      this.each((_, proxyLogger) => {
        if (this._rootLogger === proxyLogger.getLogger()) {
          proxyLogger.setLogger(rootLogger);
        }
      });

      this._rootLogger = rootLogger;
    }
  }

  getLogger(name: string) {
    let proxyLogger = this._store.get(name);

    if (!proxyLogger) {
      proxyLogger = new ProxyLogger(name, this._rootLogger);

      this._store.set(name, proxyLogger);
    }

    return proxyLogger;
  }

  setLogger(name: string, logger: Logger) {
    let proxyLogger = this._store.get(name);

    if (!proxyLogger) {
      proxyLogger = new ProxyLogger(name, logger);

      this._store.set(name, proxyLogger);
    } else {
      proxyLogger.setLogger(logger);
    }
  }
}

export default LoggerManager;
