import global from './global';
import DebugLogger from './DebugLogger';
import LoggerManager from './LoggerManager';

export type { Logger, LogLevel } from './types';
export { default as LoggerManager } from './LoggerManager';
export { default as DebugLogger } from './DebugLogger';
export { default as EventLogger } from './EventLogger';

const GLOBAL_LOGGER_MANAGER_SYMBOL = Symbol.for('__GLOBAL_LOGGER_MANAGER__');

if (!(GLOBAL_LOGGER_MANAGER_SYMBOL in global)) {
  const rootLogger = new DebugLogger('@logger');
  const loggerManager = new LoggerManager(rootLogger);

  if (typeof Object.defineProperty === 'function') {
    Object.defineProperty(global, GLOBAL_LOGGER_MANAGER_SYMBOL, {
      get() {
        return loggerManager;
      },
    });
  } else {
    (global as any)[GLOBAL_LOGGER_MANAGER_SYMBOL] = loggerManager;
  }
}

export const loggerManager: LoggerManager = (global as any)[GLOBAL_LOGGER_MANAGER_SYMBOL];
