import debug, { Debugger } from 'debug';

import { Logger } from './types';

class DebugLogger implements Logger {
  static enable(namespaces: string) {
    return debug.enable(namespaces);
  }

  static disable() {
    return debug.disable();
  }

  private _namespace: string;

  private _silly: Debugger;

  private _debug: Debugger;

  private _verbose: Debugger;

  private _info: Debugger;

  private _warn: Debugger;

  private _error: Debugger;

  constructor(namespace: string) {
    this._namespace = namespace;

    const log = debug(namespace);

    this._silly = log.extend('silly');
    this._debug = log.extend('debug');
    this._verbose = log.extend('verbose');
    this._info = log.extend('info');
    this._warn = log.extend('warn');
    this._error = log.extend('error');
  }

  get namespace() {
    return this._namespace;
  }

  get enabled() {
    return debug.enabled(`${this.namespace}:*`);
  }

  silly(message?: any, ...optionalParams: any[]) {
    return this._silly(message, ...optionalParams);
  }

  debug(message?: any, ...optionalParams: any[]) {
    return this._debug(message, ...optionalParams);
  }

  verbose(message?: any, ...optionalParams: any[]) {
    return this._verbose(message, ...optionalParams);
  }

  info(message?: any, ...optionalParams: any[]) {
    return this._info(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    return this._warn(message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]) {
    return this._error(message, ...optionalParams);
  }
}

export default DebugLogger;
